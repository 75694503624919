<template>
  <div class="ma-4">
    <amplify-authenticator>
      <amplify-sign-up
        :form-fields.prop="formFields"
        slot="sign-up"
      ></amplify-sign-up>
      <amplify-sign-in
        slot="sign-in"
        :hide-sign-up="true"
        username-alias="username"
      >
      </amplify-sign-in>
    </amplify-authenticator>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authConfig: {
        defaultCountryCode: "44",
        hiddenDefaults: ["phone_number"],
      },
      formFields: [
        { type: "username" },
        { type: "password" },
        { type: "email" },
      ],
    };
  },
};
</script>
